input[type="checkbox"] {
    /* Hide the default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 2px solid #00DAE7;
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }
  
  input[type="checkbox"]:checked {
    background-color: #00DAE7;
  }
  
  input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 6px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  

  .checkboxWrapper{
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .checkboxWrapper .RadioBtn{
    margin-top: 5px;
  }

  .register{
    margin-top: 20px;
  }
  .registerBtn{
    background-color:#00DAE7;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040 inset;
    color: #000000;
    margin-top: 10px;
  }

  .btn-gradient{
    color: #ffff;
    background: linear-gradient(180deg, #00DAE7 0%, #0079A8 100%);
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 10px 30px;
    border-radius: 20px;
  }