.timeSheetHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
}

.timeSheetBox {
    border: 1px solid #0079A833;
    box-shadow: 4px 4px 10px 0px #0000001A;
    box-shadow: -4px -4px 10px 0px #0000001A;
    padding: 30px;
    border-radius: 20px;
    text-align: center;
    width: 32%;
}

.timeSheetBox h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 26.82px;
    color: #0079A8;
}

.timeSheetBox h6 {
    font-size: 40px;
    font-weight: 400;
    line-height: 48.76px;
    color: #000000;
    margin-top: 10px;
}

.timeSheetTable {
    margin-top: 50px;
    border: 1px solid #0079A833;
    box-shadow: 4px 4px 10px 0px #0000001A;
    box-shadow: -4px -4px 10px 0px #0000001A;
    padding: 20px;
    border-radius: 20px;
    background-color: #ffff;

}

.timeSheetTable table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

.timeSheetTable table thead tr {
    border-bottom: 1px solid #000000;
}

.timeSheetTable table thead th {
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    color: #000000;
    padding-bottom: 10px;
}

.timeSheetTable table tbody tr {
    border-bottom: 0.5px solid #0000004D;
}

.timeSheetTable table tbody td {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    padding: 20px 0px;

}