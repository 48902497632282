*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)){all: unset;display: revert}*,*::before,*::after{box-sizing: border-box}html{-moz-text-size-adjust: none;-webkit-text-size-adjust: none;text-size-adjust: none}a, button{cursor: revert}ol, ul, menu, summary{list-style: none}img{max-inline-size: 100%;max-block-size: 100%}table{border-collapse: collapse}input, textarea{-webkit-user-select: auto;user-select: auto}textarea{white-space: revert}meter{-webkit-appearance: revert;appearance: revert}:where(pre){all: revert;box-sizing: border-box}::placeholder{color: unset}:where([hidden]){display: none}:where([contenteditable]:not([contenteditable="false"])){-moz-user-modify: read-write;-webkit-user-modify: read-write;overflow-wrap: break-word;-webkit-line-break: after-white-space;line-break: after-white-space;-webkit-user-select: auto;user-select: auto}:where([draggable="true"]){-webkit-user-drag: element}:where(dialog:modal){all: revert;box-sizing: border-box}::-webkit-details-marker{display: none}

:root {
	--primary-color: #4de5ee;
	--secondary-color: #3f87bb;
	--light-color: #ffffff;
	--border-gr-2-000: linear-gradient(0deg, var(--primary-color) 0%, var(--secondary-color) 100%);
	--border-gr-2-090: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
	--border-gr-2-180: linear-gradient(180deg, var(--primary-color) 0%, var(--secondary-color) 100%);
	--border-gr-2-270: linear-gradient(270deg, var(--primary-color) 0%, var(--secondary-color) 100%);
	--border-gr-3-000: linear-gradient(0deg, var(--light-color) 0%, var(--primary-color) 50%, var(--secondary-color) 100%);
	--border-gr-3-090: linear-gradient(90deg, var(--light-color) 0%, var(--primary-color) 50%, var(--secondary-color) 100%);
	--border-gr-3-180: linear-gradient(180deg, var(--light-color) 0%, var(--primary-color) 50%, var(--secondary-color) 100%);
	--border-gr-3-270: linear-gradient(270deg, var(--light-color) 0%, var(--primary-color) 50%, var(--secondary-color) 100%);
	--radius: 25px;
	--grad-pad: 2px;
	--rad-fix: calc(var(--radius) - var(--grad-pad));
	--transitionLength: 0.4s;
	--transitionTiming: ease;
}

html {
	height: 100svh;
}

body {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	min-height: 100svh;

	h1, h2, h3, h4, h5, h6 {
		font-weight: bold;
		color: var(--secondary-color);
		text-transform: uppercase;
	}

	h1 {font-size: 20px;}
	h2 {font-size: 18px;}
	h3 {font-size: 16px;}
	h4 {font-size: 14px;}
	h5 {font-size: 12px;}
	h6 {font-size: 10px;}

	a {
		color: var(--primary-color);
		text-shadow: 0px 1px 0px #00000080;
		transition: color 0.1s linear;
	
		&:hover,
		&:focus, 
		&:focus-within {
			color: var(--secondary-color);
		}
	
	}

	button {
		display: block;
		margin: 0 auto;
		text-align: center;
		background: var(--border-gr-2-180);
		padding: 3px 30px;
		border-radius: 99px;
		text-transform: uppercase;
		color: #ffffff;
		font-weight: bold;
		box-shadow: inset 0 -2px 3px 0 #00000030, 0 5px 5px 0 #00000030;
		transition: box-shadow 0.1s;

		&:hover,
		&:focus, 
		&:focus-within {
			box-shadow: inset 0 5px 5px 0 #00000030, 0 0 0 0 #00000030;
			background: var(--primary-color);
		}

	}

}

.login {
	--bg-color: #efefef;
	background: var(--bg-color);
	display: grid;
	place-items: center;
	grid-template-columns: minmax(0, 1fr);
	place-items: center;
	isolation: isolate;

	&::before,
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		width: 100px;
		aspect-ratio: 1 / 1;
		background: #efefef00;
	} 

	&::before {
		inset: 0 auto auto 0;
		translate: 50% 50%;
		box-shadow:
			-30px 30px 20px 20px #0000001a,
			
			50px -70px 0px 0 var(--bg-color),
			30px -20px 20px 15px #00000020,

			100px 100px 0 0 var(--bg-color),
			80px 140px 20px 10px #0000001a,

			170px 20px 0 0 var(--bg-color),
			130px 50px 20px 10px #00000020,

			-50px 90px 0px 0 var(--bg-color),
			-80px 100px 20px 10px #00000020;
	}

	&::after {
		inset: auto 0 0 auto;
		translate: -50% -50%;
		box-shadow:
			-30px 30px 20px 20px #0000001a,
			
			50px -70px 0px 0 var(--bg-color),
			30px -20px 20px 15px #00000020,

			100px 100px 0 0 var(--bg-color),
			80px 140px 20px 10px #0000001a,

			-170px 20px 0 0 var(--bg-color),
			-180px 50px 20px 10px #00000020,

			-80px 90px 0px 0 var(--bg-color),
			-100px 100px 20px 10px #00000020;
	}

	main {
		display: grid;
		grid-template-columns: minmax(0, 1fr);
		place-items: center;
		width: 100%;
		padding: 20px;
		min-height: 100%;

		.container {
			position: relative;
			isolation: isolate;
			overflow: clip;
			border-radius: var(--radius);
			padding: var(--grad-pad);
			margin: 0 auto;
			width: 100%;
			max-width: 500px;
			box-shadow: 0px 10px 20px 10px #00000020;
	
			&::before {
				content: '';
				position: absolute;
				inset: 0;
				background: var(--border-gr-2-180);
				z-index: -1;
			}
	
			.inner {
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;
				gap: 30px;
				background: #fff;
				border-radius: var(--rad-fix);
				overflow: clip;
				padding: 10%;
				position: relative;

				&::before {
					content: '';
					position: absolute;
					inset: auto auto 0 0;
					background: url("../src/assets/images/deco.avif") 0% 100% / contain no-repeat;
					opacity: 0.25;
					width: 25%;
					aspect-ratio: 1 / 1;
				}
	
				.logo {
					width: 75%;
					aspect-ratio: 1.5 / 1;
					object-fit: contain;
				}

				.register {
					display: flex;
					flex-flow: column;
					align-items: center;
					text-align: center;

					a {
						font-weight: bold;
					}

				}
	
			}
	
		}

	}

}

.dashboard {
	display: grid;
	grid-template-areas: 
		"nav header"
		"nav main";
	grid-template-columns: minmax(0, 225px) minmax(0, 1fr);
	grid-template-rows: auto minmax(0, 1fr);
	min-height: 100svh;
	transition: grid-template-columns 0.2s;
	overflow-x: clip;

	&:has(#nav:checked) {
		grid-template-columns: minmax(0, 0) minmax(0, 1fr);
	}

	nav {
		grid-area: nav;
		z-index: 2;
		box-shadow: 2px 0 4px 0 #00000030;
		position: relative;

		#nav {
			display: block;
			position: absolute;
			bottom: 50%;
			right: 0;
			translate: 100% 50%;
			width: 10px;
			aspect-ratio: 1 / 10;
			z-index: 30;
			background: #00000030;
		}
	
		.container {
			position: sticky;
			top: 0;
			isolation: isolate;
			overflow-y: clip;
			padding: 0 3px 0 0;
	
			&::before {
				content: '';
				position: absolute;
				inset: 0;
				background: var(--border-gr-2-180);
				z-index: -1;
			}
	
			.inner {
				display: flex;
				flex-flow: column;
				align-items: stretch;
				min-height: 100vh;
				background: #ffffff;
				overflow-x: clip;
	
				.nav-list {
					display: flex;
					flex-flow: column;
					flex-grow: 1;
					align-items: center;
					min-height: 100%;
	
					hr {
						border: 1px solid #00000030;
						width: 80%;
					}
	
					ul {
						display: flex;
						flex-flow: column;
						align-items: flex-end;
						gap: 5px;
						padding: 10px 0;
						width: 100%;
	
						li {
							white-space: nowrap;
							width: 85%;
	
							a {
								display: block;
								padding: 10px 10px 10px 20px;
								border-radius: 15px 0 0 15px;
								color: #333;
							}
	
							&.active a {
								background: var(--primary-color);
								box-shadow: 0px 3px 3px 0 #00000030;
								font-weight: bolder;
							}
	
							&:not(.active) :is(a:hover, a:focus, a:focus-within) {
								background: #4de5ee80;
								transition: background 0.2s;
							}
	
						}
	
					}
	
				}
	
				.logo, .logout {
					display: flex;
					width: 100%;
					aspect-ratio: 2.5 / 1;
					max-height: 100px;
					position: relative;
					isolation: isolate;
	
					&::before {
						content: '';
						position: absolute;
						inset: calc(var(--grad-pad) * -1);
						background: var(--border-gr-3-270);
						z-index: -1;
						border-radius: 0 0 var(--radius) var(--radius);
					}
	
					.logo-inner, .logout-inner {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						height: 100%;
						position: relative;
						isolation: isolate;
						background: #fff;
						border-radius: 0 0 var(--rad-fix) var(--rad-fix);

						.user {
							display: flex;
							flex-flow: row nowrap;
							justify-content: space-between;
							align-items: center;
							width: 100%;
							padding: 20px;
							gap: 20px;

							> div {
								display: flex;
								flex-flow: row nowrap;
								align-items: center;
								gap: 10px;
								flex: 1 1 0;
								white-space: nowrap;
							}

							img {
								object-fit: contain;
								width: 30px;
								aspect-ratio: 1 / 1;
							}

						}

					}
	
				}
	
				.logout {
					max-height: 70px;
	
					&::before {
						border-radius: 0 var(--radius) 0 0;
						background: var(--border-gr-3-090);
					}
	
					.logout-inner {
						border-radius: 0 var(--rad-fix) 0 0;
					}

				}
	
			}
	
		}
	
	}
	
	header {
		grid-area: header;
		position: sticky;
		top: 0;
		z-index: 1;
		overflow-x: clip;
		white-space: nowrap;
	
		> div {
			background: #fff;
			padding: 20px;
			border-radius: 0 0 20px 0;
	
			&::before {
				content: '';
				position: absolute;
				inset: 0 calc(var(--grad-pad) * -1) calc(var(--grad-pad) * -1) 0;
				background: var(--border-gr-3-090);
				z-index: -1;
				border-radius: 0 0 calc(20px * 1.15) 0;
			}
	
		}
	
	}
	
	main {
		--bg-color: #fdfdfd;
		background: var(--bg-color);
		grid-area: main;
		padding: 30px;
		z-index: 0;
		overflow-x: clip;
		position: relative;
		isolation: isolate;

		&::before,
		&::after {
			content: '';
			position: fixed;
			z-index: -1;
			width: 100px;
			aspect-ratio: 1 / 1;
			background: var(--bg-color);
		} 

		&::after {
			inset: auto 0 0 auto;
			translate: -50% -0%;
			box-shadow:
				-30px 30px 20px 20px #0000001a,
			
				50px -70px 0px 0 var(--bg-color),
				30px -20px 20px 15px #00000020,

				100px 100px 0 0 var(--bg-color),
				80px 140px 20px 10px #0000001a,

				-170px 20px 0 0 var(--bg-color),
				-180px 50px 20px 10px #00000020,

				-80px 90px 0px 0 var(--bg-color),
				-100px 100px 20px 10px #00000020;
		}
	
		.container {
			max-width: 1080px;
			margin: 0 auto;
			display: flex;
			flex-flow: column nowrap;
			gap: 30px;
			min-width: 300px;

			.cards {
				--gap: 20px;
				display: flex;
				flex-flow: row wrap;
				gap: var(--gap);

				&.employee {
					display: grid;
					grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
				}

				&.settings .card {
					flex: 1 1 100%;

					.inner {
						gap: 20px;
					}

					.info {
						display: flex;
						flex-flow: row wrap;
						justify-content: space-between;
						align-items: center;

						div:has(input) {
							display: flex;
							align-items: center;
						}

						input[type=checkbox]{
							height: 0;
							width: 0;
							visibility: hidden;
						}
						
						label {
							cursor: pointer;
							text-indent: -9999px;
							width: 50px;
							aspect-ratio: 2.5 / 1;
							background: grey;
							display: flex;
							align-items: center;
							border-radius: 99px;
							position: relative;
						}
						
						label::after {
							content: '';
							position: absolute;
							left: 5%;
							height: 80%;
							aspect-ratio: 1 / 1;
							background: #fff;
							border-radius: 99px;
							transition: 0.3s;
						}
						
						input:checked + label {
							background: var(--secondary-color);
						}
						
						input:checked + label:after {
							left: 95%;
							transform: translateX(-100%);
						}


					}

				}

				&.info {
					
					.info {
						display: flex;
						flex-flow: row wrap;
						justify-content: space-between;
						align-items: center;
						gap: 20px;
						box-shadow: 0 1px 0 0 #00000080;
						padding: 0 0 20px 0;

						&.ru {
							box-shadow: unset;
						}

						span {
							font-size: 12px;;
						}

					}

				}

				.card {
					flex: 1 1 0;
					display: flex;
					flex-flow: column;
					justify-content: space-between;
					border-radius: var(--radius);
					background: #ffffff;
					box-shadow: 0 0 5px 1px #00000030;
					overflow: clip;
					position: relative;
					padding: var(--grad-pad);
					min-width: 200px;
					background: var(--border-gr-2-180);

					&:has(> .details), &:has( > .heading) {
						background: var(--border-gr-2-270);
					}

					&.tasks {
						flex: 1 1 0;
					}

					&.shortcuts {
						flex: 2 1 0;

						.shortcut {
							display: flex;
							flex-flow: row wrap;
							justify-content: space-around;
							gap: 15px;
	
							.short {
								flex: 1 1 0;
								display: flex;
								flex-flow: column;
								align-items: center;
								text-align: center;
								color: #000;
	
								div {
									position: relative;
									isolation: isolate;

									img {
										width: 50px;
										aspect-ratio: 1 / 1;
										object-fit: contain;
									}
		
									&::before {
										content: '';
										position: absolute;
										inset: 0;
										translate: -40% -10%;
										width: 50px;
										aspect-ratio: 1 / 1;
										background: var(--primary-color);
										border-radius: 50%;
										z-index: -1;
									}
		
								}
	
							}
	
						}

					}

					&.clocking {
						flex: 1 1 100%;

						.table {
							display: grid;
							grid-template-columns: minmax(200px, 2fr) repeat(3, minmax(100px, 1fr));
							gap: 1px 1px;
							place-items: center;
							overflow-x: auto;
							background: #00000030;

							.th, .td {
								background: #fff;
								width: 100%;
								padding: 5px;
							}

							.th {
								font-weight: bold;
								text-align: center;
							}

							.td {
								display: flex;

								&:not(:has(> .name)) {
									justify-content: center;
								}

								& > div {
									border-radius: 99px;
									padding: 3px 10px;
									color: #fff;


									&.name {color: #000000;}
									&.blue {background: #0000ff80;}
									&.green {background: #00800080;}
									&.red {background: #ff000080;}

								}

							}

						}

					}

					&.payroll {
						flex: 2 1 0;
					}

					&.leave {
						flex: 1 1 0;
					}

					&.reports {
							flex: 1 1 100%;
					}

					.center {
						text-align: center;
						margin: 0 auto;
					}

					.left {
						text-align: left;
						margin: 0 auto 0 0;
					}

					> .heading, > .details {
						padding: var(--gap);
						text-align: center;
						display: flex;
						flex-flow: column;
						align-items: center;
						gap: 5px;

						h1, h2, h3, h4, h5, h6 {
							color: #fff;
						}

						.role {
							border-radius: 99px;
							background: #ffffffaa;
							padding: 2px 15px;
						}

					}

					&:has(> .heading, > .details) .inner {
						border-radius: var(--rad-fix) 0 var(--rad-fix) var(--rad-fix);

						&::before {
							content: '';
							position: absolute;
							inset: 0 0 auto auto;
							width: var(--radius);
							height: calc(var(--radius) * 2);
							background: #00000000;
							border-radius: 0 0 var(--radius) 0;
							translate: 0% -100%;
							box-shadow: 0 var(--radius) 0 0 #fff;
						}

					}

					> .inner {
						display: flex;
						flex-flow: column;
						gap: var(--gap);
						background: #fff;
						border-radius: var(--rad-fix);
						padding: var(--gap);
						height: 100%;
						position: relative;

						& > :nth-child(2) {
							flex: 1 1 100%;
						}

						.total {
							text-align: center;
							font-size: 30px;
						}

					}

					.heading {
						display: flex;
						flex-flow: row nowrap;
						justify-content: space-between;

						button {
							margin: 0;
						}

					}

				}

			}

			.search {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-around;
				gap: 20px;

				.search {
					display: flex;
				flex-flow: row nowrap;
				justify-content: space-around;
				gap: 20px;
				}

				input[type="submit"] {
					display: block;
					margin: 0 auto;
					text-align: center;
					background: var(--border-gr-2-180);
					padding: 15px 30px;
					border-radius: 15px;
					text-transform: uppercase;
					color: #ffffff;
					font-weight: bold;
					box-shadow: inset 0 -2px 3px 0 #00000080, 0 5px 5px 0 #00000080;
					transition: box-shadow 0.1s;
					justify-self: flex-end;
				
					&:hover,
					&:focus, 
					&:focus-within {
						box-shadow: inset 0 5px 5px 0 #00000080, 0 0 0 0 #00000080;
						background: var(--primary-color);
					}
					
				}

			}

		}
	
	}

}

form {
	display: grid;
	gap: 50px;
	white-space: nowrap;

	&.report {

		.range {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;
			gap: 20px;
		}

		.field {
			flex: 1 1 0;
			width: unset;
			gap: 20px;
		}

	}


	&:has( > .field) {
		gap: 15px
	}

	fieldset {
		--gap: 15px;
		position: relative;
		display: flex;
		flex-flow: row wrap;
		gap: var(--gap);
		padding: 20px;
		isolation: isolate;
		border: 3px solid #4de5ee;
		border-radius: 20px;
		background: #fff;
	}

	legend {
		position: absolute;
		top: 0;
		display: block;
		padding: 0 15px;
		border-radius: 20px;
		background: #fff;
		translate: 0 -60%;
		text-transform: uppercase;
	}

	.field {
		position: relative;
		display: flex;
		align-items: center;
		border: 2px solid #4de5ee;
		border-radius: 22px;
		padding: 10px 15px;
		width: calc(50% - var(--gap));
		flex: 1 1 auto;
		background: #fff;

		&:has(input:focus, select:focus) {
			box-shadow: 0 0 5px 1px #00000030;
		}

		label {
			position: absolute;
			padding: 0 5px;
			background: white;
			border-radius: 99px;
			transition: 0.2s ease all;
			top: 47.5%;
			translate: 0 -50%;
		}

		label:has(+ input:focus),
		label:has(+ input:not(:focus):valid),
		label:has(+ select:focus),
		label:has(+ select:not(:focus):valid) {
			font-size: 11px;
			top: 0;
		}

		input, select {
			z-index: 1;
			width: 100%;
		}
			
	}

	input[type="submit"] {
		display: block;
		margin: 0 auto;
		text-align: center;
		background: var(--border-gr-2-180);
		padding: 15px 30px;
		border-radius: 15px;
		text-transform: uppercase;
		color: #ffffff;
		font-weight: bold;
		box-shadow: inset 0 -2px 3px 0 #00000080, 0 5px 5px 0 #00000080;
		transition: box-shadow 0.1s;

		&:hover,
		&:focus, 
		&:focus-within {
			box-shadow: inset 0 5px 5px 0 #00000080, 0 0 0 0 #00000080;
			background: var(--primary-color);
		}

	}

}

.accordion-animation-wrapper {
  display: grid;
  grid-template-rows: 0fr;
	gap: 20px;
  overflow: hidden;
  transition: grid-template-rows var(--transitionLength) var(--transitionTiming);
}

.accordion-trigger-input:checked ~ .accordion-animation-wrapper {
  grid-template-rows: 1fr;
}

.accordion-animation {
  min-height: 0;
}

.accordion-transform-wrapper {
  transform: translateY(-100%);
  visibility: hidden;
  transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s var(--transitionLength) var(--transitionTiming);
}

.accordion-trigger-input:checked ~ .accordion-animation-wrapper .accordion-transform-wrapper {
  transform: translateY(0);
  visibility: visible;
  transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s linear;
}

/* Margins inside the container will throw animation height calculations off, better use paddings */
.accordion-content * {
  margin: 0;
}

.accordion {
  min-width: 320px;
  width: 100%;
  margin: 0 auto;
  background: white;
	display: flex;
	flex-flow: column nowrap;
	gap: 20px;
}

.accordion-item {
	position: relative;
	isolation: isolate;

	&::before {
		content: '';
		position: absolute;
		inset: -2px -2px 0 -2px;
		background: var(--border-gr-3-090);
		z-index: -1;
		border-radius: 25px 25px 0 0;
	}

	

}

.accordion-trigger-input {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
	
}


.accordion-trigger {
  display: block;
  position: relative;
  padding: 16px;
  transition: box-shadow var(--transitionLength) var(--transitionTiming);
  z-index: 1;
  cursor: pointer;
	border-radius: 25px 25px 0 0;
	background-color: #fff;

}


.accordion-trigger-input:focus-visible ~ .accordion-trigger {
  outline: 2px solid;
  outline-offset: -2px;
}

.accordion-trigger::after {
  display: block;
  position: absolute;
  right: 16px;
  top: 18px;
  content: '';
  width: 8px;
  height: 8px;
  border: 2px solid #676767;
  border-bottom: transparent;
  border-left: transparent;
  transform: rotate(135deg);
  transition: transform var(--transitionLength) var(--transitionTiming);
}

.accordion-trigger-input:checked ~ .accordion-trigger::after {
  transform: rotate(135deg) rotateX(180deg) rotateY(180deg) translateY(4px) translateX(-4px);
}

.accordion-trigger-input:checked ~ .accordion-trigger {
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  transition: box-shadow calc(var(--transitionLength) / 4) var(--transitionTiming);
}

.accordion-content {
  padding: 16px;
  background: #f8f8f8;
	border-radius: 0 0 0 25px;
	display: flex;
	flex-flow: column;
	gap: 20px;

	> div {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		gap: 15px;;

		img {
			width: 40px;
			aspect-ratio: 1 / 1;
			object-fit: contain;
		}
	}

}

.accordion-content h2 {
  padding-bottom: 0.75em;
}

.accordion-content p {
  padding-bottom: 1.5em;
}

.accordion-content p:last-of-type {
  padding-bottom: 0;
}

.accordion-content img {
  max-width: 100%;
}

@media (max-width: 650px) {

	form {

		.field {
			width: 100%;
		}

	}

	.dashboard {
		grid-template-columns: minmax(0, 0) minmax(0, 1fr);

		&:has(#nav:checked) {
			grid-template-columns: minmax(0, 225px) minmax(0, 1fr);

			header, main {
				filter: blur(5px);
				transition: filter 0.2s;
			}

		}

		nav {

			#nav {
				width: 65px;
				aspect-ratio: 1 / 1;
				translate: 120% -25%;
				bottom: 0;
				overflow-x: clip;
				transition: width 0.2s;
				border-radius: 50%;
				border: solid 2px var(--primary-color);
				background: #ffffff url("../src/assets/images/deco.avif") center / 65% no-repeat;
			}
		
		}

	}

}