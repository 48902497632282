.personProfile{
  text-align: center;
}
.personProfile h2{
  color: #0079A8;
}
.role{
  margin-top: 10px;
}
.role label{
  background-color: #00DAE7;
  font-weight: 700;
  padding: 5px 60px; 
  border-radius: 20px;
  font-size: 20px;
}

.employeeSection{
  text-align: center;
  align-items: center;
  margin-top: 20px;
}
.employeeSection label{
  color: #0079A8;
  font-weight: 700;
  padding-left: 10px;
  font-size: 20px;
}
.employeeSection label span{
  color: black;
  border-right: 2px solid #0079A8;
  padding-right: 10px;
  /* font-weight: 500; */
}
/* .lastBorder label{
  color: black;
  padding-right: 10px;
} */
.lastBorder label span{
  color: black;
  font-weight: 500;
  padding-right: 10px;
}
.SubmitBtn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SubmitBtn h2{
font-size: 22px;
font-weight: 700;
line-height: 26.82px;
color: #0079A8;
}
.SubmitBtn input{
  justify-content: end !important;
}