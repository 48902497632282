.clockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.clockHeaderInput {
    border: 2px solid #0079A8;
    border-radius: 20px;
    padding-left: 7px;
    color: #0000004D;
    font-size: 15px;
    font-weight: 400;
    align-items: center;
    display: flex;
}

.clockHeaderlink {
    background: linear-gradient(180deg, #00DAE7 0%, #0079A8 100%);
    color: #ffff;
    padding: 15px 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;

}

.clockTabel {
    width: 100%;
    height: auto;
    box-shadow: 4px 4px 10px 0px #0000001A;
    margin-top: 40px;
    padding: 20px;
}

.clockTabel table {
    width: 100%;
    border-collapse: collapse;
}

.clockTabel thead th {
    color: #000000;
    font-size: 15px;
    font-weight: 700;
    line-height: 18.29px;
    text-align: center;
    padding-bottom: 10px;
    background-color: #ffff;

}


#tdFirstColum {
    font-size: 10px;
    font-weight: 700;
    line-height: 12.19px;
}

.clockTabel tbody td {
    font-size: 15px;
    font-weight: 400;
    line-height: 18.29px;
    text-align: center;
    padding-top: 20px;
    border-bottom: 0.5px solid #0000004D;
}

#clockShift {
    background-color: #00DAE7;
    padding: 5px 40px;
    border-radius: 20px;
}

#timeIn {
    background-color: #43F56A4D;
    border-radius: 20px;
    padding: 5px 40px;
}

#timeOut {
    background-color: #FF2E2E4D;
    border-radius: 20px;
    padding: 7px 40px;
}

.clockTabel tbody td span {
    font-size: 10px;
    font-weight: 400;
    line-height: 12.19px;

}

.borderBottom {
    border-bottom: 1px solid #000000;
}

.borderRight {
    border-right: 0.5px solid #0000004D;
}


.actionBtns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.actionBtns img {
    margin-left: 5px;
}


.cardsSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.cardsSection .cards {
    width: 48%;
    height: auto;
    border: 1px solid #0079A8;
    box-shadow: 4px 4px 10px 0px #0000001A;
    box-shadow: -4px -4px 10px 0px #0000001A;
    border-radius: 20px;
}

.cardsSection .card {
    background: linear-gradient(89.34deg, #00DAE7 0.55%, #0079A8 99.45%);
    border-radius: 20px;
    padding: 30px 20px 40px;
}

.cardsSection .card h3 {
    color: #ffff;
}

.cardsSection .inner {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px 0px 20px 20px;
    padding: 20px;
    border-bottom: 1px solid black;
    margin-top: -30px;
    height: 100px;
}

.cardsSection .inner p {
    font-size: 15px;
    font-weight: 400;
    line-height: 18.29px;
}

.cardsSection .inner p span {
    font-size: 10px;
    font-weight: 400;
    line-height: 12.19px;

}